export default {
  proxy_path: "https://ov9dqb1q4m.execute-api.eu-west-1.amazonaws.com/api",
  proxy_path_geojson_api: "https://6pyj0oxuea.execute-api.eu-west-1.amazonaws.com/api",
  google_api_key: "AIzaSyDVZjTV9W6b88zZ1cSax2y9SVBUckHtjCo",
  main_company_term_id: "6",
  crm_base_url: "macquarie.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.macquarie.forwoodsafety.com",
  MAP_REPORT_ACCESS_ROLE: "MapReportAccess",
  forwoodId: {
    URL: "https://id.macquarie.forwoodsafety.com",
    APP_CLIENT_ID: "1mdvq7vur3or6f0g6a44cs71b5",
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://euqnbc14q2.execute-api.eu-west-1.amazonaws.com/prod",
  },
  reactApp: {
    HOSTNAME: "https://mapreport.macquarie.forwoodsafety.com",
  },
  get forwoodIdAuthUrl() {
    return `${
      this.forwoodId.URL
    }/authorize?response_type=token&scope=openid&client_id=${
      this.forwoodId.APP_CLIENT_ID
    }&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  },
};
